* {
  box-sizing: border-box;
  -webkit-font-smoothing: none;
}

::selection {
  color: #fff;
  background: #00007b;
}

html {
  font-size: 12px;
}

body {
  height: 100vh;
  background: #007d7b;
  border-top: 1px solid #0000;
  margin: 0;
  font-family: Gulim, sans-serif;
  line-height: 1;
}

._12m58zx0 {
  border: 1px solid;
}

._12m58zx1 {
  border-color: #7b7b7b #fff #fff #7b7b7b;
  box-shadow: inset 1px 1px #000, inset -1px -1px #c2c2c2;
}

._12m58zx2 {
  border-color: #fff #000 #000 #fff;
  box-shadow: inset 1px 1px #c2c2c2, inset -1px -1px #7b7b7b;
}

._12m58zx3 {
  border-color: #7b7b7b #fff #fff #7b7b7b;
  box-shadow: inset 1px 1px #fff, inset -1px -1px #7b7b7b;
}

._12m58zx4 {
  padding: 1px;
}

.j1mqew0 {
  height: 180px;
}

.j1mqew1 {
  flex-grow: 1;
  margin: 0;
  padding: 12px;
}

.j1mqew2 {
  margin: 0;
  padding: 12px;
}

.j1mqew2 + .j1mqew2, .j1mqew3 + .j1mqew3 {
  margin-top: 12px;
}

.j1mqew4 {
  background: #c2c2c2;
  padding: 0 3px;
}

.j1mqew5 {
  margin-top: 8px;
}

._15f6mru0 {
  padding: 8px;
}

._15f6mru1 {
  width: 100%;
  height: 100%;
}

._15f6mru1 > canvas {
  display: block;
}

._12m58zx0 {
  border: 1px solid;
}

._12m58zx1 {
  border-color: #7b7b7b #fff #fff #7b7b7b;
  box-shadow: inset 1px 1px #000, inset -1px -1px #c2c2c2;
}

._12m58zx2 {
  border-color: #fff #000 #000 #fff;
  box-shadow: inset 1px 1px #c2c2c2, inset -1px -1px #7b7b7b;
}

._12m58zx3 {
  border-color: #7b7b7b #fff #fff #7b7b7b;
  box-shadow: inset 1px 1px #fff, inset -1px -1px #7b7b7b;
}

._12m58zx4 {
  padding: 1px;
}

._1tscu4h0 {
  min-width: 88px;
  height: 24px;
  color: #000;
  vertical-align: bottom;
  background: #c2c2c2;
  padding: 3px 16px 6px;
  font-family: inherit;
  font-size: 1em;
  line-height: 1;
  position: relative;
}

._1tscu4h0:not(:disabled):active, ._1tscu4h0:focus {
  border-color: #000;
}

._1tscu4h0:focus {
  outline: none;
  box-shadow: inset 1px 1px #fff, inset -1px -1px #000, inset -2px -2px #7b7b7b;
}

._1tscu4h0:focus:after {
  content: "";
  pointer-events: none;
  border: 1px dotted #000;
  display: block;
  position: absolute;
  inset: 3px;
}

._1tscu4h0:not(:disabled):active {
  box-shadow: inset 1px 1px #7b7b7b, inset -1px -1px #7b7b7b;
}

._1tscu4h0:disabled {
  color: #7b7b7b;
  text-shadow: 1px 1px #fff;
}

._1tscu4h1 {
  display: block;
}

:not(:disabled):active > ._1tscu4h1 {
  transform: translateY(1px);
}

._12m58zx0 {
  border: 1px solid;
}

._12m58zx1 {
  border-color: #7b7b7b #fff #fff #7b7b7b;
  box-shadow: inset 1px 1px #000, inset -1px -1px #c2c2c2;
}

._12m58zx2 {
  border-color: #fff #000 #000 #fff;
  box-shadow: inset 1px 1px #c2c2c2, inset -1px -1px #7b7b7b;
}

._12m58zx3 {
  border-color: #7b7b7b #fff #fff #7b7b7b;
  box-shadow: inset 1px 1px #fff, inset -1px -1px #7b7b7b;
}

._12m58zx4 {
  padding: 1px;
}

._1mlqzor0 {
  display: inline-block;
  position: relative;
}

._1mlqzor1 {
  content: "";
  width: 13px;
  height: 13px;
  box-sizing: border-box;
  background: #fff;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

._1mlqzor0:active > :not(:disabled) ~ ._1mlqzor1 {
  background: #c2c2c2;
}

._1mlqzor2 {
  width: 0;
  height: 0;
  opacity: 0;
  background: none;
  border: 0;
  margin: 0;
  position: fixed;
  top: 0;
}

._1mlqzor3 {
  width: 9px;
  height: 9px;
  visibility: hidden;
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
}

:checked ~ ._1mlqzor3 {
  visibility: visible;
}

._1mlqzor4 {
  margin-left: 17px;
}

:focus ~ ._1mlqzor4 {
  outline: 1px dotted #000;
}

._127a10h0 {
  -webkit-user-select: none;
  user-select: none;
  background: #c2c2c2;
  border: 1px solid #000;
  border-color: #c2c2c2 #000 #000 #c2c2c2;
  box-shadow: inset 1px 1px #fff, inset -1px -1px #7b7b7b;
}

._127a10h1 {
  padding: 2px;
}

._127a10h2 {
  padding: 3px;
}

._127a10h3 {
  padding: 1px 2px;
  display: flex;
}

._127a10h4 {
  color: #fff;
  background: #00007b;
}

._127a10h5 {
  color: #c2c2c2;
  background: #7b7b7b;
}

._127a10h6 {
  width: 16px;
  height: 16px;
  image-rendering: pixelated;
  margin-right: 3px;
}

._127a10h7 {
  margin: 0;
  padding: 2px 0;
  font-size: 1rem;
  font-weight: 400;
}

._1bk2kyq0 {
  height: 21px;
}

._1bk2kyq1 {
  width: 100%;
  height: 100%;
  background: #fff;
  border: none;
  padding: 1px 1px 4px;
  font-family: inherit;
  font-size: 12px;
  line-height: 1;
  display: block;
}

._1bk2kyq1:focus {
  outline: none;
}

._1bk2kyq1:disabled, ._1bk2kyq1:read-only {
  background: #c2c2c2;
}

._1bk2kyq1:disabled {
  color: #7b7b7b;
}

._12m58zx0 {
  border: 1px solid;
}

._12m58zx1 {
  border-color: #7b7b7b #fff #fff #7b7b7b;
  box-shadow: inset 1px 1px #000, inset -1px -1px #c2c2c2;
}

._12m58zx2 {
  border-color: #fff #000 #000 #fff;
  box-shadow: inset 1px 1px #c2c2c2, inset -1px -1px #7b7b7b;
}

._12m58zx3 {
  border-color: #7b7b7b #fff #fff #7b7b7b;
  box-shadow: inset 1px 1px #fff, inset -1px -1px #7b7b7b;
}

._12m58zx4 {
  padding: 1px;
}

.iyqn4s0 {
  width: 100%;
  height: 100%;
  resize: none;
  background: #fff;
  border: none;
  margin: 0;
  padding: 1px 1px 4px;
  font-family: inherit;
  font-size: 12px;
  line-height: 1;
  display: block;
  overflow: hidden;
}

.iyqn4s0:focus {
  outline: none;
}

.iyqn4s0:disabled, .iyqn4s0:read-only {
  background: #c2c2c2;
}

.iyqn4s0:disabled {
  color: #7b7b7b;
}

/*# sourceMappingURL=index.510945ac.css.map */
