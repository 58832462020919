._1tscu4h0 {
  min-width: 88px;
  height: 24px;
  background: #c2c2c2;
  color: #000;
  font-family: inherit;
  font-size: 1em;
  line-height: 1;
  padding: 3px 16px 6px;
  position: relative;
  vertical-align: bottom;
}
._1tscu4h0:not(:disabled):active, ._1tscu4h0:focus {
  border-color: #000;
}
._1tscu4h0:focus {
  box-shadow: inset 1px 1px #fff, inset -1px -1px #000, inset -2px -2px #7b7b7b;
  outline: none;
}
._1tscu4h0:focus::after {
  content: "";
  display: block;
  border: 1px dotted #000;
  pointer-events: none;
  position: absolute;
  inset: 3px;
}
._1tscu4h0:not(:disabled):active {
  box-shadow: inset 1px 1px #7b7b7b, inset -1px -1px #7b7b7b;
}
._1tscu4h0:disabled {
  color: #7b7b7b;
  text-shadow: 1px 1px #fff;
}
._1tscu4h1 {
  display: block;
}
:not(:disabled):active > ._1tscu4h1 {
  transform: translateY(1px);
}