._127a10h0 {
  background: #c2c2c2;
  border: 1px solid #c2c2c2;
  border-right-color: #000;
  border-bottom-color: #000;
  box-shadow: inset 1px 1px #fff, inset -1px -1px #7b7b7b;
  user-select: none;
}
._127a10h1 {
  padding: 2px;
}
._127a10h2 {
  padding: 3px;
}
._127a10h3 {
  display: flex;
  padding: 1px 2px;
}
._127a10h4 {
  background: #00007b;
  color: #fff;
}
._127a10h5 {
  background: #7b7b7b;
  color: #c2c2c2;
}
._127a10h6 {
  width: 16px;
  height: 16px;
  image-rendering: pixelated;
  margin-right: 3px;
}
._127a10h7 {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  padding: 2px 0;
}