._1bk2kyq0 {
  height: 21px;
}
._1bk2kyq1 {
  width: 100%;
  height: 100%;
  background: #fff;
  border: none;
  display: block;
  font-family: inherit;
  font-size: 12px;
  line-height: 1;
  padding: 1px 1px 4px;
}
._1bk2kyq1:focus {
  outline: none;
}
._1bk2kyq1:disabled, ._1bk2kyq1:read-only {
  background: #c2c2c2;
}
._1bk2kyq1:disabled {
  color: #7b7b7b;
}