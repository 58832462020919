._12m58zx0 {
  border: 1px solid;
}
._12m58zx1 {
  border-color: #7b7b7b;
  border-right-color: #fff;
  border-bottom-color: #fff;
  box-shadow: inset 1px 1px #000, inset -1px -1px #c2c2c2;
}
._12m58zx2 {
  border-color: #fff;
  border-right-color: #000;
  border-bottom-color: #000;
  box-shadow: inset 1px 1px #c2c2c2, inset -1px -1px #7b7b7b;
}
._12m58zx3 {
  border-color: #7b7b7b;
  border-right-color: #fff;
  border-bottom-color: #fff;
  box-shadow: inset 1px 1px #fff, inset -1px -1px #7b7b7b;
}
._12m58zx4 {
  padding: 1px;
}