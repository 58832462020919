._1mlqzor0 {
  display: inline-block;
  position: relative;
}
._1mlqzor1 {
  content: "";
  width: 13px;
  height: 13px;
  background: #fff;
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}
._1mlqzor0:active > :not(:disabled) ~ ._1mlqzor1 {
  background: #c2c2c2;
}
._1mlqzor2 {
  width: 0;
  height: 0;
  background: none;
  border: 0;
  margin: 0;
  opacity: 0;
  position: fixed;
  top: 0;
}
._1mlqzor3 {
  width: 9px;
  height: 9px;
  display: block;
  visibility: hidden;
  position: absolute;
  top: 2px;
  left: 2px;
}
:checked ~ ._1mlqzor3 {
  visibility: visible;
}
._1mlqzor4 {
  margin-left: 17px;
}
:focus ~ ._1mlqzor4 {
  outline: 1px dotted #000;
}