* {
  box-sizing: border-box;
  -webkit-font-smoothing: none;
}

*::selection {
  background: #00007b;
  color: #fff;
}

html {
  font-size: 12px;
}

body {
  height: 100vh;
  background: #007d7b;
  border-top: 1px solid transparent;
  font-family: Gulim, sans-serif;
  line-height: 1;
  margin: 0;
}
