.j1mqew0 {
  height: 180px;
}
.j1mqew1 {
  flex-grow: 1;
  margin: 0;
  padding: 12px;
}
.j1mqew2 {
  margin: 0;
  padding: 12px;
}
.j1mqew2 + .j1mqew2 {
  margin-top: 12px;
}
.j1mqew3 + .j1mqew3 {
  margin-top: 12px;
}
.j1mqew4 {
  background: #c2c2c2;
  padding: 0 3px;
}
.j1mqew5 {
  margin-top: 8px;
}