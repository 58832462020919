.iyqn4s0 {
  width: 100%;
  height: 100%;
  background: #fff;
  border: none;
  display: block;
  font-family: inherit;
  font-size: 12px;
  line-height: 1;
  margin: 0;
  overflow: hidden;
  padding: 1px 1px 4px;
  resize: none;
}
.iyqn4s0:focus {
  outline: none;
}
.iyqn4s0:disabled, .iyqn4s0:read-only {
  background: #c2c2c2;
}
.iyqn4s0:disabled {
  color: #7b7b7b;
}